.custom-keyboard {
  font-size: 3rem;
  background-color: #F6F6F6 !important;
}

.hg-button {
  height: fit-content !important;
  border-radius: 20px !important;
  text-transform: uppercase;
  font-weight: 500;
}

.enterButton {
  font-size: 0rem;
  width: 12.4rem;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.enterButton span::before {
  font-size: 3rem;
  content: '↵';
}
