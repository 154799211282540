.warning-banner {
  display: flex;
  align-items: baseline;
  height: 100%;
}

.warning-banner-card {
  background-color: #FFD54F !important;
  border-radius: 30px;
  border: 1.5px solid #FFD54F;
}

.warning-banner-card p {
  margin: -6px 0;
}
